import { PersistentSharedState } from '@trello/shared-state';

export interface DeveloperConsoleState {
  lastActiveTab: number;
  developerConsoleOpen: boolean;
  developerConsoleEnabled: boolean;
  plainTextEditor: boolean;
  showModelIds: boolean;
  useTrelloTimingHeader: boolean;
  logConnectionInformation?: boolean;
  operationNameInUrl?: boolean;
}

const initialState: DeveloperConsoleState = {
  lastActiveTab: 0,
  developerConsoleOpen: false,
  developerConsoleEnabled: false,
  plainTextEditor: false,
  showModelIds: false,
  useTrelloTimingHeader: false,
  logConnectionInformation: undefined,
  operationNameInUrl: undefined,
};

export const developerConsoleState =
  new PersistentSharedState<DeveloperConsoleState>(initialState, {
    storageKey: 'developerConsoleState',
  });
