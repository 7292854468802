// Ensure that keys are sorted, to reduce merge conflicts
/* eslint sort-keys: "error" */

import type { SupportedFlagTypes } from '../dynamicConfig.types';

export type DynamicConfigFlagManifestValue = {
  createdOn: string;
  defaultValue: SupportedFlagTypes;
  owner: string;
};

/**
 * Dynamic configs in Statsig have to be registered here.
 * The `owner` field should be your Atlassian user name.
 */
const dynamicConfigManifest = {
  butler_ent_admin_only_allowlist: {
    createdOn: '2024-11-18',
    defaultValue: [] as string[],
    owner: 'ssilber',
  },
  trello_web_apollo_cache_hydrator_metrics: {
    createdOn: '2024-11-20',
    defaultValue: false,
    owner: 'vkozachok',
  },
  trello_web_apollo_read_write_metrics: {
    createdOn: '2024-11-18',
    defaultValue: false,
    owner: 'cilleyd',
  },
  trello_web_atlassian_team: {
    createdOn: '2024-11-13',
    defaultValue: false,
    owner: 'mfaith',
  },
  trello_web_billing_down_for_maintenance: {
    createdOn: '2024-11-08',
    defaultValue: false,
    owner: 'jmatthews',
  },
  trello_web_disconnect_active_clients: {
    createdOn: '2024-11-07',
    defaultValue: false,
    owner: 'mfaith',
  },
  trello_web_error_handling_rrs: {
    createdOn: '2024-11-20',
    defaultValue: false,
    owner: 'dcilley',
  },
  trello_web_forcefully_reload_to_exact_version: {
    createdOn: '2024-11-18',
    defaultValue: 0,
    owner: 'mfaith',
  },
  trello_web_google_tag_manager: {
    createdOn: '2024-11-04',
    defaultValue: true,
    owner: 'mfaith',
  },
  trello_web_max_first_active_delay: {
    createdOn: '2024-11-20',
    defaultValue: 2000,
    owner: 'vkozachok',
  },
  trello_web_max_first_idle_delay: {
    createdOn: '2024-11-20',
    defaultValue: 60000,
    owner: 'vkozachok',
  },
  trello_web_native_current_board_info: {
    createdOn: '2024-11-20',
    defaultValue: true,
    owner: 'vkozachok',
  },
  trello_web_passively_reload_to_minimum_version: {
    createdOn: '2024-11-18',
    defaultValue: 0,
    owner: 'mfaith',
  },
  trello_web_somethings_wrong: {
    createdOn: '2024-11-07',
    defaultValue: false,
    owner: 'mfaith',
  },
  trello_web_take_trello_offline: {
    createdOn: '2024-11-07',
    defaultValue: false,
    owner: 'mfaith',
  },
} as const;

export type RegisteredDynamicConfigFlagKey = keyof typeof dynamicConfigManifest;
// exporting the manifest as a separate object to type using the sealed const
export const dynamicConfigFlags: Record<
  RegisteredDynamicConfigFlagKey,
  DynamicConfigFlagManifestValue
> = dynamicConfigManifest;
